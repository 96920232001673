import {
    AfterViewInit,
    Component,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ChangeDetectorRef,
    NgZone,
} from '@angular/core';
import { BindObservable } from 'bind-observable';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map, pairwise, startWith, takeUntil } from 'rxjs/operators';

@Component({
    template: `
        <div
            #navbarItemWrapper
            class="nav-padding"
            boundariesElement="window"
            [tooltip]="label"
            [tooltipAnimation]="true"
            [isDisabled]="!(hasOverflow$ | async)"
            placement="bottom"
            triggers="mouseenter:mouseleave"
            container="body"
        >
            <div class="d-grid gap-2">
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </div>
        </div>
    `,
})
export class NavbarItemWrapper implements AfterViewInit, OnDestroy {
    template: TemplateRef<HTMLElement>;
    label: string;

    @BindObservable()
    watchOverflow: boolean = true;
    watchOverflow$: Observable<boolean>;

    @ViewChild('navbarItemWrapper') navbarItemWrapper: ElementRef<HTMLDivElement>;

    @BindObservable()
    hasOverflow = false;
    hasOverflow$: Observable<boolean>;

    private _initialDevicePixelRatio = window.devicePixelRatio;
    private destroy$ = new Subject<void>();

    constructor(
        private renderer2: Renderer2,
        private elementRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone
    ) {}

    ngAfterViewInit() {
        if (!this.navbarItemWrapper) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            const devicePixelRatio$ = fromEvent<Event>(window, 'resize').pipe(
                takeUntil(this.destroy$),
                map(() => window.devicePixelRatio)
            );

            const zoomIn$ = devicePixelRatio$.pipe(
                startWith(this._initialDevicePixelRatio),
                pairwise(),
                map(([prev, next]) => prev <= next)
            );

            if (this.watchOverflow) {
                // Initial check after DOM is ready
                setTimeout(() => {
                    this.checkOverflow(false);

                    // Subscribe to zoom changes
                    zoomIn$.pipe(takeUntil(this.destroy$)).subscribe(zoomIn => {
                        this.checkOverflow(zoomIn);
                        this.ngZone.run(() => this.cdr.detectChanges());
                    });

                    // Also check on window resize
                    fromEvent(window, 'resize')
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(() => {
                            this.checkOverflow(false);
                            this.ngZone.run(() => this.cdr.detectChanges());
                        });
                }, 300);
            }

            this.hasOverflow$.pipe(takeUntil(this.destroy$)).subscribe(hasOverflow => {
                if (this.navbarItemWrapper && this.navbarItemWrapper.nativeElement) {
                    if (hasOverflow) {
                        this.renderer2.addClass(this.navbarItemWrapper.nativeElement, 'only-icon');
                    } else {
                        this.renderer2.removeClass(this.navbarItemWrapper.nativeElement, 'only-icon');
                    }
                    this.ngZone.run(() => this.cdr.detectChanges());
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkOverflow(zoomIn: boolean) {
        if (!this.navbarItemWrapper || !this.navbarItemWrapper.nativeElement) {
            return;
        }

        const el = this.navbarItemWrapper.nativeElement;
        const btn = el.querySelector('.btn');

        if (!btn) return;

        if (this.hasOverflow && zoomIn) return;
        if (!zoomIn && (window.devicePixelRatio / this._initialDevicePixelRatio) * 100 > 125) return;

        try {
            const deepest = this.deepest(Array.from(el.children), null);
            if (!deepest || deepest.length === 0) return;

            const last = deepest[deepest.length - 1];
            if (!last) return;

            const deepestOffsetHeight = last['offsetHeight'] + last['offsetTop'];
            const newHasOverflow =
                btn['scrollHeight'] > btn['clientHeight'] || deepestOffsetHeight > btn['offsetHeight'];

            if (this.hasOverflow !== newHasOverflow) {
                this.hasOverflow = newHasOverflow;
            }
        } catch (error) {
            console.error('Error checking overflow:', error);
        }
    }

    private deepest(elements: Element[], selector: string): Element[] {
        if (!elements || elements.length === 0) return [];

        let deepestLevel = 0,
            deepestChildSet = new Set<Element>();

        elements.forEach(parent => {
            if (!parent) return;

            try {
                const children = parent.querySelectorAll(selector || '*');
                children.forEach((child: Element) => {
                    if (!child || !child.firstElementChild) {
                        // Skip elements with class "dont-hide"
                        if (child.classList && child.classList.contains('dont-hide')) return;

                        let levelsToParent = 0;
                        let currentElement: Element | null = child;

                        while (currentElement && currentElement !== parent) {
                            currentElement = currentElement.parentElement;
                            levelsToParent++;

                            // Safety check to prevent infinite loops
                            if (levelsToParent > 100) {
                                console.warn('Possible infinite loop detected in deepest()');
                                return;
                            }
                        }

                        if (levelsToParent > deepestLevel) {
                            deepestLevel = levelsToParent;
                            deepestChildSet = new Set([child]);
                        } else if (levelsToParent === deepestLevel) {
                            deepestChildSet.add(child);
                        }
                    }
                });
            } catch (error) {
                console.error('Error in deepest():', error);
            }
        });

        return Array.from(deepestChildSet);
    }

    private isVisible(element: Element): boolean {
        if (!element) return false;

        try {
            const style = getComputedStyle(element);
            return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
        } catch (error) {
            console.error('Error in isVisible():', error);
            return false;
        }
    }
}

@Directive({ selector: '[navbarItem]' })
export class NavbarItemDirective implements OnInit, OnDestroy {
    private _navbarItem: any;
    @Input()
    set navbarItem(value: any) {
        this._navbarItem = value;
    }

    private _label: string;
    @Input()
    set navbarItemLabel(value: string) {
        this._label = value;
        if (this.wrapperContainer && this.wrapperContainer.instance) {
            this.wrapperContainer.instance.label = value;
        }
    }

    private _watchOverflow = true;
    @Input()
    set navbarItemWatchOverflow(value: boolean) {
        this._watchOverflow = value;
        if (this.wrapperContainer && this.wrapperContainer.instance) {
            this.wrapperContainer.instance.watchOverflow = value;
        }
    }

    private wrapperContainer: ComponentRef<NavbarItemWrapper>;

    constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        this.viewContainerRef.clear();
        this.wrapperContainer = this.viewContainerRef.createComponent(NavbarItemWrapper);
        this.wrapperContainer.instance.template = this.templateRef;
        this.wrapperContainer.instance.label = this._label || '';
        this.wrapperContainer.instance.watchOverflow = this._watchOverflow;
    }

    ngOnDestroy(): void {
        if (this.wrapperContainer) {
            this.wrapperContainer.destroy();
        }
    }
}
