import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private isLoadingSubject$ = new BehaviorSubject(0);

    get isLoading$() {
        return this.isLoadingSubject$.asObservable();
    }

    constructor() {}

    private loadingStarted() {
        // Use setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.isLoadingSubject$.next(this.isLoadingSubject$.value + 1);
        });
    }

    private loadingFinished() {
        // Use setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.isLoadingSubject$.next(this.isLoadingSubject$.value - 1);
        });
    }

    private nothing() {
        this.isLoadingSubject$.next(this.isLoadingSubject$.value);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingStarted();
        return next.handle(request).pipe(
            filter(event => event.type === HttpEventType.Response),
            finalize(() => this.loadingFinished())
        );
    }
}
