import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorInterceptor } from 'portal/services/interceptors/error.interceptor';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  private applyTokenToRequest(request: HttpRequest<any>) {
    const token = AuthService.token;
    
    if (token) {
      request = request.clone(
        request.url.includes('v2')
          ? { headers: request.headers.append('X-Auth-Token', token) }
          : {
            body: ({
              ...request.body,
              ...{ token },
            }),
          },
      );
    }

    const adfsToken = AuthService.adfsIdToken;
    if (adfsToken) {
      request = request.clone(
        request.url.includes('v2')
          ? { headers: request.headers.append('X-Adfs-Token', adfsToken) }
          : {
            body: ({
              ...request.body,
              ...{ adfsToken },
            }),
          },
      );
    }

    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 1200 == 401
    return next.handle(this.applyTokenToRequest(request)).pipe(
      catchError(e => {
        if (e.error?.error_code === 1200 || e.status === 401) {
          AuthService.removeToken();
          AuthService.removeAdfsIdTokenToken();
          ErrorInterceptor.skipError(1);
          return this.authService.authenticateByIP().pipe(
            switchMap(() => next.handle(this.applyTokenToRequest(request))),
          );
        }
        throw e;
      }),
    );
  }
}
