import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DashboardService } from 'portal/pages/dashboard/dashboard.service';
import { PromptUpdateService } from 'portal/prompt-update.service';
import { BusinessCalendarService } from 'portal/services/business-calendar.service';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { LoadingInterceptor } from './services/interceptors/loading.interceptor';
import { CheckForUpdateService } from 'portal/check-for-update.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    template: `
        <div
            [ngClass]="{ 'my-show': isLoading, 'my-hide': !isLoading }"
            class="progress animated fixed-top"
            style="height: 3px; z-index: 100500"
        >
            <div
                class="progress-bar progress-bar-animated progress-bar-striped bg-primary"
                role="progressbar"
                style="width: 100%"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <router-outlet></router-outlet>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    isLoading = false;
    isLoading$: Observable<number>;

    constructor(
        private cdr: ChangeDetectorRef,
        private li: LoadingInterceptor,
        checkForUpdateService: CheckForUpdateService,
        updateService: PromptUpdateService,
        private dashboardService: DashboardService,
        private titleService: Title,
        private businessCalendarService: BusinessCalendarService
    ) {
        this.titleService.setTitle(environment.title);
        this.isLoading$ = this.li.isLoading$;
    }

    ngOnInit() {
        // Subscribe to loading changes and mark for check when they happen
        this.isLoading$
            .pipe(
                tap(count => {
                    this.isLoading = count > 0;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }
}
