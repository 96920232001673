import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual, orderBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BookingHeaderCellComponent } from 'portal/pages/main/booking/booking-header-cell/booking-header-cell';
import { ServiceDeskRequest } from 'portal/pages/main/service-desk/service-desk.interfaces';
import { PermissionsService, permStub } from 'portal/services/permissions.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, finalize, map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';
import { ListResponse } from 'shared/interfaces/list';
import { ApiResponse } from 'shared/interfaces/response';
import { ModalService } from 'shared/modules/modal/modal.service';
import { CellData, ColumnConfig } from 'shared/modules/table/table-config.model';
import { isNullOrUndefined, Utils } from 'shared/utils/utils';
import { MakeConferenceBooking } from '../place-booking/types';
import { Booking, BookingRow, PlaceCol, SelectedCells, TimeTableResponse } from './booking.interfaces';
import { TimeFromTimeToCell } from './time-from-time-to-cell/time-from-time-to-cell';

// noinspection DuplicatedCode
@Injectable()
export class BookingService {
    private selectedDateSubject$ = new BehaviorSubject<Date>(Utils.now);
    cellSize = 0;

    get selectedDate$() {
        return this.selectedDateSubject$.asObservable();
    }

    private selectedCellsSubject$ = new BehaviorSubject<any>({});

    rowsAndColumns$: Observable<{
        columns: ColumnConfig<PlaceCol>[];
        rows: BookingRow[];
    }> = this.selectedDateSubject$.asObservable().pipe(
        switchMap(selectedDate => {
            return combineLatest([
                this.http.get<ApiResponse<TimeTableResponse>>('/api/v2/booking/meeting-room/timetable', {
                    params: { date: Utils.dateToBackend(selectedDate) },
                }),
                this.http.get<ListResponse<ServiceDeskRequest>>('/api/v2/service-desk/list', {
                    params: {
                        skip_user_check: '1',
                        category_id: '1',
                        search_string: Utils.dateToBackend(selectedDate),
                        limit: '30',
                    },
                }),
            ]).pipe(
                map(([bookings, serviceDeskRequests]) => {
                    const placeColumns: ColumnConfig<PlaceCol>[] = bookings.data.header.map((data: PlaceCol) => ({
                        prop: `item.${data.id}`,
                        headerComponent: BookingHeaderCellComponent,
                        component: this.cmp,
                        data,
                    }));
                    placeColumns.push({
                        prop: `item.1000`,
                        headerComponent: BookingHeaderCellComponent,
                        component: this.cmp,
                        data: {
                            id: 1000,
                            name: 'Переход',
                            private: false,
                            bookable: false,
                            internal_phone: null,
                            capacity: 'От 15 человек',
                            description: 'От 15 до 70 человек',
                        },
                    });
                    const columns: ColumnConfig<PlaceCol>[] = [
                        {
                            name: 'Время',
                            component: TimeFromTimeToCell,
                        },
                        ...placeColumns,
                    ];
                    this.prepareSelectedCellsDataStructure(placeColumns);

                    bookings.data.rows.forEach(r => {
                        orderBy(serviceDeskRequests.data.items, 'timeFrom').forEach(d => {
                            if (d.payload.time_from >= r.time_to || r.time_from >= d.payload.time_to) {
                                if (!!isNullOrUndefined(r['places']['1000']) === false) {
                                    return;
                                }
                                r['places']['1000'] = null;
                                return;
                            }
                            r['places']['1000'] = {
                                user_id: d.applicant_user_id,
                                user_name: `${d.payload.purpose}`,
                                employee_id: d.applicant_employee_id,
                            };
                        });
                    });
                    const rows = this.computeRows(bookings);

                    this.computeRowSpans(rows);
                    this.cellSize = rows[0].time_to - rows[0].time_from;
                    return { columns, rows };
                })
            );
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    bookings$: Observable<Booking[]> = this.selectedCellsSubject$.asObservable().pipe(
        map(selectedCells => {
            let bookings: Booking[] = [];
            // Iterate over dates and map-places
            Object.keys(selectedCells).forEach(placeId => {
                // Constant part of query
                const bookingTemplate: Booking = {
                    date: Utils.dateToBackend(this.selectedDateSubject$.value),
                    place_id: parseInt(placeId, 10),
                    time_from: 0,
                    time_to: 0,
                };

                // Обрабатываем обычные бронирования
                // array of timeFrom F.E. [600, 630, 750]
                const timeFromArray = Object.keys(selectedCells[placeId])
                    .filter(key => key !== 'partial' && selectedCells[placeId][key])
                    .map(timeFrom => parseInt(timeFrom, 10))
                    .sort((a, b) => (a > b ? 1 : -1));

                // if some cells are selected
                if (timeFromArray.length) {
                    const bookingsForPlace = timeFromArray.reduce<Booking[]>(
                        (arrayOfBookings, time_from) => {
                            const lastEntry = arrayOfBookings[arrayOfBookings.length - 1];

                            const isFirstIteration = lastEntry.time_from === 0;
                            const nextCellIsAfterOther = lastEntry.time_to === time_from;

                            if (isFirstIteration) {
                                lastEntry.time_from = time_from;
                                lastEntry.time_to = time_from + this.cellSize;
                            } else if (nextCellIsAfterOther) {
                                lastEntry.time_to = time_from + this.cellSize;
                            } else {
                                // create new entry
                                arrayOfBookings.push({
                                    ...bookingTemplate,
                                    ...{
                                        time_from,
                                        time_to: time_from + this.cellSize,
                                    },
                                });
                            }
                            return arrayOfBookings;
                        },
                        [{ ...bookingTemplate }]
                    );

                    bookings = bookings.concat(bookingsForPlace);
                }

                // Обрабатываем частичные интервалы (partial)
                if (selectedCells[placeId].partial) {
                    Object.keys(selectedCells[placeId].partial).forEach(timeFrom => {
                        const partialIntervals = selectedCells[placeId].partial[timeFrom];
                        const time_from = parseInt(timeFrom, 10);

                        // Проверяем наличие выбранных интервалов
                        if (partialIntervals) {
                            // Проверяем интервал до брони
                            if (partialIntervals.before) {
                                // Получаем фактическое время из свойства before_time
                                let actual_time_from = time_from;
                                let actual_time_to = time_from + this.cellSize;

                                if (partialIntervals.before_time) {
                                    actual_time_from = partialIntervals.before_time.actual_time_from;
                                    actual_time_to = partialIntervals.before_time.actual_time_to;
                                }

                                const booking = {
                                    ...bookingTemplate,
                                    time_from,
                                    time_to: time_from + this.cellSize,
                                    // Добавляем фактическое время для частичного интервала
                                    actual_time_from,
                                    actual_time_to,
                                };
                                bookings.push(booking);
                            }

                            // Проверяем интервал после брони
                            if (partialIntervals.after) {
                                // Получаем фактическое время из свойства after_time
                                let actual_time_from = time_from;
                                let actual_time_to = time_from + this.cellSize;

                                if (partialIntervals.after_time) {
                                    actual_time_from = partialIntervals.after_time.actual_time_from;
                                    actual_time_to = partialIntervals.after_time.actual_time_to;
                                }

                                const booking = {
                                    ...bookingTemplate,
                                    time_from,
                                    time_to: time_from + this.cellSize,
                                    // Добавляем фактическое время для частичного интервала
                                    actual_time_from,
                                    actual_time_to,
                                };
                                bookings.push(booking);
                            }
                        }
                    });
                }
            });
            return bookings;
        })
    );

    showCancelBookings$ = this.getShowCancelBookings$();

    constructor(
        private http: HttpClient,
        private cmp: any,
        private permissionsService: PermissionsService,
        private modalService: ModalService,
        private toastr: ToastrService
    ) {}

    setSelectedDate(val: Date) {
        this.selectedDateSubject$.next(val);
    }

    private computeRows(res: any) {
        return res.data.rows.map(x => {
            const places = {};

            // Обрабатываем каждое место
            Object.keys(x.places).forEach(placeId => {
                const placeData = x.places[placeId];

                // Если есть данные о бронировании
                if (placeData) {
                    // Копируем данные и добавляем фактическое время только если оно явно указано
                    places[placeId] = {
                        ...placeData,
                    };

                    // Проверяем, есть ли фактическое время в данных
                    if (placeData.actual_time_from !== undefined && placeData.actual_time_to !== undefined) {
                        places[placeId].actual_time_from = placeData.actual_time_from;
                        places[placeId].actual_time_to = placeData.actual_time_to;
                    }
                } else {
                    places[placeId] = placeData;
                }
            });

            return {
                ...places,
                time_from: x.time_from,
                time_to: x.time_to,
                rowspan: {},
            };
        });
    }

    private computeRowSpans(rows: BookingRow[]) {
        const placesIds = Object.keys(rows[0])
            .map(x => parseInt(x, 10))
            .filter(x => !!x);

        const memory: {
            [placeId: number]: { value?: string; index?: number };
        } = placesIds.map(placeId => ({ [placeId]: {} })).reduce((a, b) => ({ ...a, ...b }), {});

        for (let i = 0; i < rows.length; i++) {
            for (const placeId of placesIds) {
                const memoryForPlace = memory[placeId];
                const rowValue = rows[i][placeId];
                if (isNullOrUndefined(rowValue)) {
                    // Free cell
                    memoryForPlace.value = undefined;
                    memoryForPlace.index = undefined;
                } else if (isEqual(memoryForPlace.value, rowValue)) {
                    // Current cell same as memoized
                    rows[i].rowspan[placeId] = 'none';
                    rows[memory[placeId].index].rowspan[placeId] =
                        (rows[memory[placeId].index].rowspan[placeId] as number) + 1 || 2;
                } else {
                    // memoize cell
                    memoryForPlace.value = rowValue;
                    memoryForPlace.index = i;
                }
            }
        }
    }

    // tslint:disable-next-line:max-line-length
    createTemplateInfoForm$(): Observable<{
        minTimeFrom: number;
        maxTimeFrom: number;
        places: { label: string; value: number }[];
        minTimeTo: number;
        step: number;
        maxTimeTo: number;
    }> {
        return this.rowsAndColumns$.pipe(
            map(({ rows, columns }) => {
                const places = columns
                    .filter(col => col.data)
                    .map(col => ({ label: col.data.name, value: col.data.id }));
                const minTimeFrom = rows[0].time_from;
                const minTimeTo = rows[0].time_to;
                const maxTimeFrom = rows[rows.length - 1].time_from;
                const maxTimeTo = rows[rows.length - 1].time_to;
                const step = this.cellSize;
                return {
                    minTimeFrom,
                    minTimeTo,
                    maxTimeFrom,
                    maxTimeTo,
                    step,
                    places,
                };
            })
        );
    }

    isCellSelected$(data: CellData<BookingRow, PlaceCol>): Observable<boolean> {
        const placeId = data.column.data.id;
        const time = data.row.time_from;
        return this.selectedCellsSubject$.pipe(
            map(selectedCells => selectedCells[placeId] && selectedCells[placeId][time]),
            distinctUntilChanged()
        );
    }

    isCellAvaliable(data: CellData<BookingRow, PlaceCol>) {
        if (data.value) {
            return false;
        }

        // if (data.column.data.hasOwnProperty("bookable")) {
        //     return data.column.data.bookable;
        // }

        if (Utils.isToday(this.selectedDateSubject$.value)) {
            const timeNow = new Date();
            const timeInRow = new Date();
            timeInRow.setHours(0, 0, 0, 0);
            timeInRow.setMinutes(data.row.time_from + this.cellSize);
            return timeNow < timeInRow;
        }
        return true;
    }

    toggleSelected(data: CellData<BookingRow, PlaceCol>) {
        const placeId = data.column.data.id;
        const time = data.row.time_from;
        const selectedCells = this.selectedCellsSubject$.value;
        selectedCells[placeId][time] = !selectedCells[placeId][time];

        this.selectedCellsSubject$.next(selectedCells);
    }

    /**
     * Проверяет, выбран ли частичный интервал (до или после существующей брони)
     */
    isPartialIntervalSelected$(
        data: CellData<BookingRow, PlaceCol>,
        intervalType: 'before' | 'after'
    ): Observable<boolean> {
        const placeId = data.column.data.id;
        const time = data.row.time_from;

        // Проверяем доступность слота по времени
        if (!this.isTimeSlotAvailable(data, intervalType)) {
            return of(false);
        }

        return this.selectedCellsSubject$.pipe(
            map(selectedCells => {
                if (
                    !selectedCells[placeId] ||
                    !selectedCells[placeId].partial ||
                    !selectedCells[placeId].partial[time]
                ) {
                    return false;
                }
                return !!selectedCells[placeId].partial[time][intervalType];
            }),
            distinctUntilChanged()
        );
    }

    /**
     * Проверяет, доступен ли временной слот (не прошло ли уже время)
     */
    private isTimeSlotAvailable(data: CellData<BookingRow, PlaceCol>, intervalType: 'before' | 'after'): boolean {
        // Если нет данных о бронировании, слот недоступен
        if (!data.value || !data.value.actual_time_from || !data.value.actual_time_to) {
            return false;
        }

        const slotTimeFrom = data.row.time_from;
        const slotTimeTo =
            data.row.time_from +
            (data.row.time_to - data.row.time_from) * ((data.row.rowspan[data.column.data.id] as number) || 1);

        // Определяем границы интервала
        let intervalFrom: number, intervalTo: number;
        if (intervalType === 'before') {
            intervalFrom = slotTimeFrom;
            intervalTo = data.value.actual_time_from;
        } else {
            // 'after'
            intervalFrom = data.value.actual_time_to;
            intervalTo = slotTimeTo;
        }

        // Если сегодняшний день, проверяем текущее время
        if (Utils.isToday(this.selectedDateSubject$.value)) {
            const now = new Date();
            const currentMinutes = now.getHours() * 60 + now.getMinutes();

            // Слот недоступен, если его начало меньше текущего времени
            if (intervalFrom <= currentMinutes) {
                return false;
            }
        }

        return true;
    }

    /**
     * Переключает выбор частичного интервала (до или после существующей брони)
     */
    togglePartialIntervalSelected(data: any, intervalType: 'before' | 'after') {
        // Проверяем доступность слота по времени
        if (!this.isTimeSlotAvailable(data, intervalType)) {
            // Если слот недоступен, ничего не делаем
            return;
        }

        const placeId = data.column.data.id;
        const time = data.row.time_from;
        const selectedCells = this.selectedCellsSubject$.value;

        // Инициализируем структуру данных, если она еще не существует
        if (!selectedCells[placeId]) {
            selectedCells[placeId] = {};
        }

        if (!selectedCells[placeId].partial) {
            selectedCells[placeId].partial = {};
        }

        if (!selectedCells[placeId].partial[time]) {
            selectedCells[placeId].partial[time] = {};
        }

        // Определяем фактическое время для интервала
        let actualTimeFrom = data.value.actual_time_from;
        let actualTimeTo = data.value.actual_time_to;

        // Если в переданных данных есть информация о частичном интервале, используем ее
        if (data.partial) {
            actualTimeFrom = data.partial.actual_time_from;
            actualTimeTo = data.partial.actual_time_to;
        } else {
            // Иначе вычисляем на основе типа интервала
            const slotTimeFrom = data.row.time_from;
            const slotTimeTo =
                data.row.time_from +
                (data.row.time_to - data.row.time_from) * ((data.row.rowspan[data.column.data.id] as number) || 1);

            if (intervalType === 'before') {
                actualTimeFrom = slotTimeFrom;
                actualTimeTo = data.value.actual_time_from;
            } else {
                // 'after'
                actualTimeFrom = data.value.actual_time_to;
                actualTimeTo = slotTimeTo;
            }
        }

        // Если интервал уже выбран, снимаем выбор
        if (selectedCells[placeId].partial[time][intervalType]) {
            selectedCells[placeId].partial[time][intervalType] = false;
        } else {
            // Иначе сохраняем информацию о фактическом времени
            selectedCells[placeId].partial[time][intervalType] = true;

            // Сохраняем фактическое время в отдельном свойстве
            selectedCells[placeId].partial[time][`${intervalType}_time`] = {
                actual_time_from: actualTimeFrom,
                actual_time_to: actualTimeTo,
            };
        }

        this.selectedCellsSubject$.next(selectedCells);
    }

    private prepareSelectedCellsDataStructure(placeColumns: ColumnConfig<PlaceCol>[]) {
        placeColumns.forEach(col => {
            const placeId = col.data.id;
            if (isNullOrUndefined(this.selectedCellsSubject$.value)) {
                this.selectedCellsSubject$.next({});
            }
            if (isNullOrUndefined(this.selectedCellsSubject$.value[placeId])) {
                this.selectedCellsSubject$.value[col.data.id] = {};
            }
        });
    }

    makeBookings() {
        return this.bookings$.pipe(
            take(1),
            switchMap(x => of(...x)),
            mergeMap((booking: Booking) => {
                if (booking.place_id === 1000) {
                    return this.modalService.createForm({
                        title: 'Уточнить бронирование перехода',
                        form: [
                            {
                                key: 'number_of_people',
                                type: 'input',
                                templateOptions: {
                                    label: 'Количество приглашенных',
                                    required: true,
                                    type: 'number',
                                },
                            },
                            {
                                type: 'help-block',
                                templateOptions: {
                                    html: `<span>от 15 человек</span>`,
                                },
                            },
                            {
                                key: 'purpose',
                                type: 'input',
                                templateOptions: {
                                    label: 'Цель',
                                    required: true,
                                    type: 'text',
                                },
                            },
                            {
                                type: 'help-block',
                                templateOptions: {
                                    html: `<span>В комментарии укажите, что потребуется для встречи (вода, печенье), как расставить столы и тд. Обращаем ваше внимание, что для организации IT-поддержки встречи необходимо подать отдельную заявку в IT</span>`,
                                },
                            },
                            {
                                type: 'textarea',
                                key: 'description',
                                templateOptions: {
                                    label: 'Комментарий',
                                    rows: 3,
                                },
                            },
                        ],
                        onSubmit: (form: { number_of_people; purpose; description }) => {
                            return this.http
                                .post('/api/v2/service-desk', {
                                    category_id: 1,
                                    type_id: 6,
                                    office_id: 1,
                                    title: 'Бронь перехода',
                                    description: form.description,
                                    payload: {
                                        number_of_people: form.number_of_people,
                                        purpose: form.purpose,
                                        office_place: 'Кунцево Плаза',
                                        date: booking.date,
                                        time_from: booking.time_from,
                                        time_to: booking.time_to,
                                    },
                                })
                                .pipe(
                                    finalize(() =>
                                        this.toastr.info(
                                            'Бронь перехода появится, когда будет принята сотрудниками АХО'
                                        )
                                    )
                                );
                        },
                    });
                }

                const conferenceBooking: any = {
                    booking_place_id: booking.place_id,
                    date: booking.date,
                    time_slots: [{ from: booking.time_from, to: booking.time_to }],
                };

                // Если у нас есть фактическое время из частичного интервала
                if (booking.actual_time_from && booking.actual_time_to) {
                    conferenceBooking.time_slots[0].from = booking.actual_time_from;
                    conferenceBooking.time_slots[0].to = booking.actual_time_to;
                }

                return this.http.post<MakeConferenceBooking>('/api/v2/booking/meeting-room', conferenceBooking);
            }),
            finalize(() => this.reloadTimetable())
        );
    }

    cancelBookings(reqBodies: { uid: string }[]) {
        return forkJoin(reqBodies.map(body => this.http.post('/api/v2/booking/meeting-room/cancel-room', body))).pipe(
            finalize(() => this.reloadTimetable())
        );
    }

    reloadTimetable() {
        this.selectedDateSubject$.next(this.selectedDateSubject$.value);
        this.showCancelBookings$ = this.getShowCancelBookings$();
    }

    private getShowCancelBookings$(): Observable<boolean> {
        const canCancelAny = this.permissionsService.getPermissionValue(permStub.booking.timetable.cancelAny);
        return canCancelAny
            ? of(true)
            : this.http.post<any>('/api/booking/get_booking_list', {}).pipe(map(res => !!res.data.total));
    }
}
