import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SingleFile } from 'portal/chunks/files/files.interfaces';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
})
export class InfoModalComponent {
    title = 'Инфо';
    closeButtonTitle = 'Закрыть';
    message: string;
    modalRef: BsModalRef;
    files?: SingleFile[];
}
