<app-modal [title]="title" [modalRef]="modalRef">
    <div class="modal-body">
        <div [innerHTML]="message"></div>
        <ul *ngIf="files" class="list-unstyled">
            <li *ngFor="let file of files" >
                <a [href]="file.url" target="_blank">{{ file.basename }}</a>
            </li>
        </ul>
    </div>
    <div class="modal-footer" *ngIf="closeButtonTitle !== '@hide'">
        <button class="btn btn-primary" (click)="modalRef.hide()">
            {{ closeButtonTitle }}
        </button>
    </div>
</app-modal>
